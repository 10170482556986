$( function() {
	$('.variation-colors.preview').on('click', 'a', function(e) {
		e.preventDefault();
		$li = $(this).closest('li');
		$(this).closest('ul').find('li').removeClass('active');
		$li.addClass('active');
		var _src = $li.data('image');
		$li.closest('.product').find('.img-wrapper').find('img').attr('src', _src);

		console.log("clicked");
	});
	$('.variation-colors.preview').each( function() {
		$(this).find('li').first().find('a').trigger('click');
	});
});