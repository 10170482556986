/*$( function() {
	var scrolldistance;
	var width_visible;
	var width_products;

	function getDimensions() {
		
		//width_visible = $('.highlights-wrapper').width();
		//width_products = $('.highlights-wrapper').find('ul.products').width();
		//scrolldistance = parseInt($('.highlights-wrapper').find('ul.products').width() / $('.highlights-wrapper').find('ul.products').children('li').length);
		//$('.highlights-wrapper').height( $('.highlights-wrapper').find('ul.products').first().height() + 100);
		

		$('.highlights-wrapper').each( function() {
			$(this).data( 'width-visible', $(this).width() );
			$(this).data( 'width-products', $(this).find('ul.products').width() );
			$(this).data( 'scrolldistance', parseInt( $(this).find('ul.products').width() / $(this).find('ul.products').children('li').length ) );
			//$(this).height( $(this).find('ul.products').first().height() );
		});
		console.log('getting');
	}

	$(window).on('resize', getDimensions);
	getDimensions();

	$('.highlights-arrow.prev').on('click', function() {

		var $wrapper = $(this).closest('.highlights-wrapper');
		var $highlights = $wrapper.find('ul.products');

		$highlights.children('li').stop();
		$li = $highlights.children('li').last().clone();
		$highlights.children('li').last().removeClass('fadeIn').addClass('fadeOff').delay(1000).queue( function() {
			$(this).closest('ul').find('li').removeClass('fadeIn').removeClass('fadeOff');
			$(this).remove();
		});
		$li.removeClass('fadeOff').addClass('fadeIn');
		$li.prependTo( $highlights );

	});

	$('.highlights-arrow.next').on('click', function() {
		var $wrapper = $(this).closest('.highlights-wrapper');
		var $highlights = $wrapper.find('ul.products');
		
		$highlights.children('li').stop();
		$li = $highlights.children('li').first().clone();
		$highlights.children('li').first().removeClass('fadeIn2').addClass('fadeOff2').delay(1000).queue( function() {
			$(this).closest('ul').find('li').removeClass('fadeIn2').removeClass('fadeOff2');
			$(this).remove();
		});
		$li.removeClass('fadeOff2').addClass('fadeIn2');
		$li.appendTo( $highlights );

	});

});*/
var sly = [];
var _options = {};
		$( function() {
			$('.frame').each( function() {
				var $frame = $(this);

				if($frame.data('setheight')) {
					var heights = $("div.panel").map( function() {
						return $(this).height();
					}).get();
					var maxHeight = Math.max.apply(null, heights);
					$frame.height( maxHeight );
					$frame.find('li').height( maxHeight );
				}

				var _i = $frame.data('identifier');

				_options = {
					horizontal: 1,
					itemNav: $frame.data('itemnav'),
					speed: 300,
					mouseDragging: 1,
					touchDragging: 1,
					smart:true,
					prev: $frame.parent().find('.prev'),
					next: $frame.parent().find('.next'),
				};

				var evts = {
					load : function() {
						$frame.find('ul.slidee').addClass('loaded');
						sly[_i].activate(0);
					}
				};
				sly[_i] = new Sly( $frame , _options, evts);

				$frame.parent().find('.next').on('click', function(evt) {
					if( $frame.find('.slidee').find('li:last-child').is('.active') ) {
						sly[_i].activate(0);
						evt.stopImmediatePropagation();
					}
				});

				$frame.parent().find('.prev').on('click', function(evt) {
					if( $frame.find('.slidee').find('li:first-child').is('.active') ) {
						sly[_i].activate(sly[_i].items.length - 1);
						evt.stopImmediatePropagation();
					}
				});

				sly[_i].init();

				$( window ).on('resize', function() {
					console.log('ok');
					sly[_i].reload();
				});
			});
		});