$( function() {
	$(window).on('resize', shadowHeader);
	function shadowHeader() {
		var _h = $('header').outerHeight();
		$('.header-shadow').height( _h );
		$('form.finder').css('top', _h );
	}

	shadowHeader();

	$(window).on('scroll', function() {
		if( $(window).scrollTop() >= 20 ) {
			$('header').addClass('scrolled');
		}
		else {
			$('header').removeClass('scrolled');
		}
	});

	$('#togglesearch').on('click', function() {
		if($('body').hasClass('search-active')) {
			$('body').removeClass('search-active');
		}
		else {
			$('body').addClass('search-active');
			$('form[name=search]').find('input[name=s]').focus();
		}
	});

	$('#togglesearch-full').on('click', function() {
		$('body').removeClass('search-active');
	});

	$('#togglenav').on('click', function() {
		$('header').toggleClass('nav-active');
	});

	$('nav.main').find('li.menu-item-has-children').each( function() {
		var $btn = $('<span class="togglesub"></span>');
		$btn.on('click', function() {
			$(this).closest('li').toggleClass('subtoggled');
		});
		$(this).append( $btn );
	});

	$('nav.main > ul').find('li.nolink').children('a').on('click', function(e) {
		e.preventDefault();
	});

	$(window).on('resize', navHeight);

	function navHeight() {
		var _h = $(window).height() - $('header').height();
		$('nav.main > ul').find('li').css('max-height', _h);
	}
	navHeight();

	function initNav() {
		$('nav.main').find('li.current-menu-item, li.current-menu-parent').addClass('subtoggled');
	}
	initNav();

	$('.btn-filter').on('click', function() {
		$('.wrapper-loop').toggleClass('filter-active');
	});

});