function setCookie(cname,cvalue,exdays) {
	const d = new Date();
	d.setTime(d.getTime() + (exdays*24*60*60*1000));
	let expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
	let name = cname + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for(let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

ready( function() {
	let modals = document.querySelectorAll('.popupModal');
	if(modals.length) {
		modals.forEach( function(el) {
			if(getCookie(el.dataset.cname) == "") {
				el.classList.remove('disabled');
	
				el.querySelector('.btn-close').addEventListener('click', function(evt) {
					evt.preventDefault();
					let modal = evt.target.parentNode;
					modal.classList.add('disabled');
					setCookie(modal.dataset.cname, true, 1);
				});
	
				let btn = el.querySelector('a.btn');
				if(btn) {
					btn.addEventListener('click', function(evt) {
						evt.preventDefault();
						let modal = evt.target.parentNode;
						setCookie(modal.dataset.cname, true, 1);
						location.href = evt.target.href;
					});
				}
			}
		});
	}
});